<template>
	<var-skeleton :rows="20" :loading="loading">
		<var-row>
			<breadcrumbs current-item-title="Deployment Report" :items="breadcrumbItems" />
		</var-row>
		<h4>Preparing</h4>
		<info-detail-box
			name="Initial checks"
			:items="deploymentReport?.initial"
			ref="infoDetailBox"
			:status="deploymentReport.meta?.initial"
		/>

		<h4 class="mt-5">Running BEFORE commands</h4>
		<command-box
			v-for="deploymentCommand in deploymentReport.before_commands"
			:ref="'commandBox' + deploymentCommand.command_id"
			:key="deploymentCommand.command_id"
			:server="deployment.server"
			:name="deploymentCommand.description"
			:text="deploymentCommand.text"
			:log="deploymentCommand.log ?? undefined"
			:status="deploymentCommand.status"
		/>

		<h4 class="mt-5">Transferring</h4>
		<var-space direction="column" size="20">
			<files-info-box
				ref="filesInfoBox"
				:items="deploymentReport?.files"
				name="Uploading files to the server"
				:status="deploymentReport.meta?.files"
			/>

			<files-info-box
				ref="environmentFilesInfoBox"
				name="Uploading environment files to the server"
				:items="deploymentReport?.environment_files"
				:status="deploymentReport.meta?.environment_files"
			/>
		</var-space>

		<h4 class="mt-5">Running AFTER commands</h4>
		<command-box
			v-for="deploymentCommand in deploymentReport.after_commands"
			:ref="'commandBox' + deploymentCommand.command_id"
			:key="deploymentCommand.command_id"
			:server="deployment.server"
			:name="deploymentCommand.description"
			:text="deploymentCommand.text"
			:log="deploymentCommand.log ?? undefined"
			:status="deploymentCommand.status"
		/>

		<h4 class="mt-5">Finishing</h4>
		<info-detail-box
			ref="notificationInfoBox"
			name="Sending notifications"
			:items="deploymentReport?.notifications"
			:status="deploymentReport.meta?.notifications"
		/>
	</var-skeleton>
</template>

<script>
import request from "../request";
import InfoDetailBox from "../components/InfoDetailBox";
import CommandBox from "../components/CommandBox";
import FilesInfoBox from "../components/FilesInfoBox";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Echo from "laravel-echo";

export default {
	name: "DeploymentReport",
	components: {Breadcrumbs, FilesInfoBox, CommandBox, InfoDetailBox},
	created() {
		this.getDeploymentDetails()
		this.pako = require('pako')
	},
	data() {
		return {
			loading: true,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			deployment: null,
			deploymentReport: [],
			echo: null,
			channel: null,
			commandStream: '',
			pako: null
		}
	},
	methods: {
		initSocket() {
			if (!this.echo) {
				console.log('init socket')
				this.echo = new Echo({
					authEndpoint: process.env.VUE_APP_DOMAIN + '/broadcasting/auth',
					auth: {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('token'),
							'Accept': 'application/json'
						}
					},
					broadcaster: 'pusher',
					key: process.env.VUE_APP_PUSHER_APP_KEY,
					wsHost: process.env.VUE_APP_PUSHER_HOST,
					wsPort: process.env.VUE_APP_PUSHER_PORT,
					forceTLS: false
				})

				this.channel = this.echo
					.private('gantry-deployment.' + this.deployment.id)
					.listen('.deployment-event', (data) => {
						if (data.compress) {
							const base64Decoded = atob(data.data)
							const charData = base64Decoded.split('').map(x => x.charCodeAt(0))
							const binData = new Uint8Array(charData)
							const inflatedData = this.pako.inflate(binData)

							const result = String.fromCharCode.apply(null, new Uint16Array(inflatedData))

							this.deploymentReport = JSON.parse(result)
						} else {
							this.deploymentReport = data.data
						}

						if (this.deployment.status !== 'in_progress') {
							this.deployment.status = 'in_progress'
						}

						if (this.deploymentReport.meta?.deployment_status === 'finished') {
							setTimeout(() => {
								this.$router.push({
									name: 'DeploymentDetails',
									params: {
										project_id: this.$route.params.project_id,
										deployment_id: this.deployment.id
									}
								})
							}, 2000);
						}
					})
			}
		},
		getDeploymentDetails() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + `/projects/${this.$route.params.project_id}/deployments/${this.$route.params.deployment_id}/report`)
				.then(data => {
					this.deployment = data.data

					this.breadcrumbItems.push({
						title: this.deployment.project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.project_id
						}
					})

					this.breadcrumbItems.push({
						title: 'Deployment Details',
						to: 'DeploymentDetails',
						params: {
							project_id: this.$route.params.project_id,
							deployment_id: this.$route.params.deployment_id
						}
					})

					if (this.deployment.status === 'created' || this.deployment.status === 'running') {
						this.initSocket()
					} else {
						this.deploymentReport = this.deployment.report
					}

					this.loading = false
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	},
	beforeUnmount() {
		if (this.echo) {
			this.echo.leaveChannel('gantry-deployment.' + this.deployment.id)
		}
	},
}
</script>

<style>

.xterm-viewport::-webkit-scrollbar, #container::-webkit-scrollbar {
	width: 12px !important;
}

.xterm-viewport::-webkit-scrollbar-track, #container::-webkit-scrollbar-track {
	background-color: #2f2f2f !important;
//border-radius: 10px !important;
}

.xterm-viewport::-webkit-scrollbar-thumb, #container::-webkit-scrollbar-thumb {
	background-color: #666 !important;
//border-radius: 10px !important;
}

.xterm-viewport::-webkit-scrollbar-thumb:hover, #container::-webkit-scrollbar-thumb:hover {
	background-color: #888 !important;
}

</style>