<template>
	<div class="d-flex justify-content-start align-content-center p-3 gap-3">
		<var-icon v-if="state === 'finished'" name="check-circle-outline" color="#00FF00" size="28" />
		<var-loading v-else-if="state === 'in_progress'" type="circle" />
		<var-icon v-else-if="state === 'failed'" name="close-circle-outline" color="#ff0000" size="28" />
		<var-icon v-else name="dots-vertical" size="28" />
		<h4 :class="'align-self-center m-0 ' + state">{{ title }}</h4>
	</div>
	<var-divider margin="0" />
</template>

<script>
export default {
	name: "InfoBox",
	props: {
		state: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		}
	},
	data() {
		return {}
	}
}
</script>

<style scoped>

.finished {
	color: green;
}

.failed {
	color: red;
}

</style>