<template>
	<div class="var-elevation--2">
		<info-box :title="'Running command ' + name" :state="status"/>
		<div :id="'terminal-' + name"></div>
	</div>
</template>

<script>
import 'xterm/css/xterm.css'
import {Terminal} from 'xterm'
import {FitAddon} from 'xterm-addon-fit';
import InfoBox from "./InfoBox";

export default {
	name: "CommandBox",
	components: {InfoBox},
	watch: {
		status(newVal, oldVal) {
			if (newVal === 'in_progress') {
				this.term.write(`\u001b[32;1m${this.server.username}@gantry-ci\x1B[0m:\u001b[34;1m${this.server.path} \x1B[0m$ `);
			}
		},
		log(newVal, oldVal) {
			const result = newVal.replace(oldVal, '')
			this.writeLog(result)
		}
	},
	mounted() {
		if (!this.term) {
			this.term = new Terminal({
				fontSize: 16,
				theme: {
					background: '#300A24'
				},
				fontFamily: 'Ubuntu Mono, courier-new, courier, monospace'
			})
			this.fitAddon = new FitAddon();
			this.term.loadAddon(this.fitAddon);
			this.term.open(document.getElementById('terminal-' + this.name))
			this.fitAddon.fit();

			window.addEventListener("resize", this.resizeTerminal);

			this.term.write(`\u001b[32;1m${this.server.username}@gantry-ci\x1B[0m:\u001b[34;1m${this.server.path} \x1B[0m$ `);
			this.term.writeln(this.text);

			this.writeLog(this.log)

			if (this.status !== 'in_progress') {
				this.term.write(`\u001b[32;1m${this.server.username}@gantry-ci\x1B[0m:\u001b[34;1m${this.server.path} \x1B[0m$ `);
			}
		}
	},
	props: {
		server: {
			type: Object,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		status: {
			type: String,
			required: true
		},
		text: {
			type: String,
			required: true
		},
		log: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			term: null,
			fitAddon: null
		}
	},
	methods: {
		writeLog(log) {
			if (this.log.includes('\n')) {
				this.term.write(log.replaceAll('\n', '\r\n'))
			} else {
				this.term.write(log)
			}
		},
		resizeTerminal() {
			if (this.fitAddon) {
				this.fitAddon.fit();
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resizeTerminal);
	},
}
</script>

<style scoped>

</style>