<template>
	<div class="var-elevation--2">
		<info-box :title="name" :state="status"/>
		<var-divider margin="0"/>
		<var-cell v-for="item in items" :class="item.status">{{ item.log }}</var-cell>
	</div>
</template>

<script>
import InfoBox from "./InfoBox";

export default {
	name: "InfoDetailBox",
	components: {InfoBox},
	props: {
		name: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: false
		},
		status: {
			type: String,
			required: true
		}
	},
	data() {
		return {
		}
	}
}
</script>

<style scoped>

.finished {
	color: green;
}

.failed {
	color: red;
}

</style>