<template>
	<div class="var-elevation--2">
		<info-box :title="name" :state="status" />
		<div ref="container" id="container" class="overflow-auto list">
			<var-cell v-for="item in items" :class="item.status">
				{{ calculateLabel(item) }} <var-progress v-if="item.percentage && item.percentage != 100" label :ripple="item.percentage != 100" :color="item.percentage == 100 ? '#0fc48f' : ''" :value="item.percentage" />
			</var-cell>
		</div>
	</div>
</template>

<script>
import InfoBox from "./InfoBox";
export default {
	name: "itemsInfoBox",
	components: {InfoBox},
	props: {
		name: {
			type: String,
			required: true
		},
		status: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: false
		},
	},
	watch: {
		items(newVal, oldVal) {
			this.scrollToBottom()
		}
	},
	mounted() {
		this.scrollToBottom()
	},
	data() {
		return {}
	},
	methods: {
		calculateLabel(item) {
			switch (item.file_status) {
				case 'deleted':
					return `Removing ${item.name}`
				case 'renamed':
					return `Renaming ${item.name}`
				default:
					return `Uploading ${item.name}`
			}
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const container = this.$refs.container;
				container.scrollTop = container.scrollHeight;
			});
		}
	}
}
</script>

<style scoped>

.list {
	max-height: 250px;
}

.failed {
	color: #f44336;
}

</style>